import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Logo"
      src="https://finezza.in/wp-content/uploads/2019/04/finezza-logo-1.png"
      {...props}
    />
  );
}

export default Logo;
