/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT'
  // ONE_DARK: 'ONE_DARK',
  // UNICORN: 'UNICORN'
};
export const dateFormat = 'YYYY-MM-DD';
export const dateFormat1 = 'DD-MMM-YYYY';

export const displayDateTime = 'YYYY-MM-DD hh:mm a';
export const displayDateTimeInTabularView = 'DD/MM/YYYY hh:mm a';

export const messages = {
  noData:
    'All files are resolved. Please apply Parsed/Corrected filter to see resolved files',
  fileDeleted: 'Cannot open, File was deleted.',
  saveWarning: 'There are unsaved changes. Please save them before parsing.',
  parsed: 'Successfully parsed',
  selectHeaders: 'Select all the headers',
  fixErrors: 'Fix all the errors in transactions',
  fileSaved: 'File saved successfully',
  updateStatus: 'Update File Status to In Progress',
  updateSuccess: 'Status updated to In Progress',
  markedError: 'File marked as Error successfully',
  selectError: 'Select Error type',
  copyPassword: 'Password copied',
  noStatement: 'Fetching statement please wait...',
  noInsert: 'This action is not allowed on Headers',
  noDelete: 'Cannot delete headers',
  fileUploaded: 'File uploaded successfully',
  fileRemove: 'File removed successfully',
  fileNotCorrect:
    'File name does not match with the downloaded file. Please upload correct file.',
  noChangeOnPageColumn: 'This action is not allowed for this column',
  comment: 'Add comment',
  selectReason: 'Select Reason type',
  selectBank: 'Select bank',
  selectParser: 'Select parser',
  selectId: 'Select files',
  FileNotError: 'Parsed file cannot mark as error',
  NoAccount:
    'All accounts are resolved. Please apply Analysed/Corrected filter to see resolved accounts'
};
export const errorReason = {
  error1: 'Headers Not Found',
  error2: 'Missing Headers/Headers not mapped',
  error3: 'Missing Headers',
  error4: 'Headers not mapped'
};

export const axisHelpTitle = 'The options to create/draw lines on the image:';

export const axisHelpList = [
  '1. Horizontal - To create/draw horizontal lines.',
  '2. Vertical - To create/draw vertical lines.',
  '3. None - To perform no action.'
];

export const delHelpTitle = 'The options to delete/remove lines on the image:';

export const delHelpList = [
  '1. Delete all lines - To delete/remove all lines.',
  '2. Delete horizontal lines - To delete/remove all horizontal lines.',
  '3. Delete vertical lines - To delete/remove all vertical lines.'
];

export const rotateHelpText =
  'During rotation, drawing grid will be disabled. Once rotation is completed, Save the image and then draw the grid line';

export const errorDateMessage = 'Enter a valid date in DD/MM/YYYY format.';
export const minDate = 'To date must be after From date.';
