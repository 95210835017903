import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_ERROR,
  GET_TRANSACTIONS_EMPTY,
  LOADER,
  GET_TAGS,
  GET_TAGS_ERROR,
  GET_TAGS_EMPTY,
  GET_CATOPTIONS,
  GET_CATOPTIONS_ERROR,
  GET_CATOPTIONS_EMPTY,
  EDIT_TRANSACTION,
  EDIT_TRANSACTION_ERROR,
  ACCOUNT_TAGS,
  ACCOUNT_CATEGORIES,
  EDIT_TRANSCTION_FOR_LOCAL,
  EMPTY_DATA_TXNS_COUNT,
  NEW_ADDED_TXNS,
  EDIT_TRANSACTION_RESET,
  GET_TRANSACTIONS_WITH_INDEXING,
  ACCOUNT_ID_FOR_RE_ANALYSIS,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_ERROR,
  REMOVE_ADD_EDIT_DEL
} from 'src/actions/txnActions';
import produce from 'immer';

const initialTxnsState = {
  selectedFilters: [],
  page: 1,
  rowsPerPage: 100,
  orderBy: 'transaction_date',
  order: 'asc'
};

const initialState = {
  transactions: null,
  transactionsError: null,
  tagOptions: null,
  tagOptionsError: null,
  categoriesOptions: null,
  categoriesOptionsError: null,
  isLoading: false,
  txnsState: initialTxnsState,
  editTransaction: false,
  accountTags: null,
  accountCategories: null,
  editTransactionForLocal: null,
  newAddedTransaction: null,
  deleteTransaction: null,
  accountIdForReAnalysis: null
};

const txnReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADER: {
      return produce(state, draft => {
        draft.isLoading = true;
      });
    }
    case GET_TRANSACTIONS: {
      const acountData = action.payload;
      return produce(state, draft => {
        draft.transactions = acountData;
        draft.transactionsError = null;
        draft.isLoading = false;
      });
    }
    case GET_TRANSACTIONS_WITH_INDEXING: {
      const acountData = action.payload;
      return produce(state, draft => {
        draft.transactions = acountData;
        draft.transactionsError = null;
        draft.isLoading = false;
      });
    }
    case GET_TRANSACTIONS_ERROR: {
      const acountData = action.payload;
      return produce(state, draft => {
        draft.transactionsError = acountData;
        draft.isLoading = false;
      });
    }
    case GET_TRANSACTIONS_EMPTY: {
      return produce(state, draft => {
        draft.transactionsError = null;
      });
    }
    case GET_TAGS: {
      return produce(state, draft => {
        draft.tagOptions = action.payload;
        // draft.isLoading = false;
      });
    }
    case GET_TAGS_ERROR: {
      return produce(state, draft => {
        draft.tagOptionsError = action.payload;
        // draft.isLoading = false;
      });
    }
    case GET_TAGS_EMPTY: {
      return produce(state, draft => {
        draft.tagOptions = null;
        draft.tagOptionsError = null;
        // draft.isLoading = false;
      });
    }
    case GET_CATOPTIONS: {
      return produce(state, draft => {
        draft.categoriesOptions = action.payload;
        // draft.isLoading = false;
      });
    }
    case GET_CATOPTIONS_ERROR: {
      return produce(state, draft => {
        // draft.isLoading = false;
      });
    }
    case GET_CATOPTIONS_EMPTY: {
      return produce(state, draft => {
        draft.categoriesOptions = null;
        draft.categoriesOptionsError = null;
        // draft.isLoading = false;
      });
    }
    case EDIT_TRANSACTION: {
      return produce(state, draft => {
        draft.editTransaction = action.payload;
        draft.editTransactionForLocal = null;
        draft.isLoading = false;
      });
    }
    case EDIT_TRANSACTION_ERROR: {
      return produce(state, draft => {
        draft.editTransaction = action.payload;
        draft.isLoading = false;
      });
    }
    case DELETE_TRANSACTION: {
      return produce(state, draft => {
        draft.deleteTransaction = action.payload;
        draft.isLoading = false;
      });
    }
    case DELETE_TRANSACTION_ERROR: {
      return produce(state, draft => {
        draft.deleteTransaction = action.payload;
        draft.isLoading = false;
      });
    }
    case NEW_ADDED_TXNS: {
      return produce(state, draft => {
        draft.newAddedTransaction = action.payload;
        draft.isLoading = false;
      });
    }
    case REMOVE_ADD_EDIT_DEL: {
      return produce(state, draft => {
        draft.newAddedTransaction = null;
        draft.editTransaction = null;
        draft.deleteTransaction = null;
      });
    }
    case EDIT_TRANSACTION_RESET: {
      return produce(state, draft => {
        draft.newAddedTransaction = false;
        draft.editTransaction = false;
        draft.isLoading = action.payload ? true : false;
      });
    }
    case EDIT_TRANSCTION_FOR_LOCAL: {
      return produce(state, draft => {
        draft.editTransactionForLocal = action.payload;
        draft.isLoading = false;
      });
    }
    case ACCOUNT_TAGS: {
      return produce(state, draft => {
        draft.accountTags = action.payload;
      });
    }
    case ACCOUNT_CATEGORIES: {
      return produce(state, draft => {
        draft.accountCategories = action.payload;
      });
    }
    case ACCOUNT_ID_FOR_RE_ANALYSIS: {
      return produce(state, draft => {
        draft.accountIdForReAnalysis = action.payload;
        draft.isLoading = false;
      });
    }
    default: {
      return state;
    }
  }
};

export default txnReducer;
