import axiosHelper from 'src/utils/axiosHelper';
import moment from 'moment';

export const GET_TRANSACTIONS = '@accountAnalysis/get-transactions';
export const GET_TRANSACTIONS_ERROR = '@accountAnalysis/get-transactions_error';
export const GET_TRANSACTIONS_EMPTY = '@accountAnalysis/get-transactions_empty';
export const LOADER = '@accountAnalysis/loader';
export const GET_TAGS = '@account/get-tags';
export const GET_TAGS_ERROR = '@account/get-tags-error';
export const GET_TAGS_EMPTY = '@account/get-tags-empty';
export const GET_CATOPTIONS = '@account/get-catoptions';
export const GET_CATOPTIONS_ERROR = '@account/get-catoptions-error';
export const GET_CATOPTIONS_EMPTY = '@account/get-catoptions-empty';
export const EDIT_TRANSACTION = '@account/edit-transation';
export const EDIT_TRANSACTION_ERROR = '@account/edit-transaction-error';
export const DELETE_TRANSACTION = '@account/DELETE-transation';
export const DELETE_TRANSACTION_ERROR = '@account/DELETE-transaction-error';
export const ADD_TRANSACTION = '@account/add-transation';
export const ADD_TRANSACTION_ERROR = '@account/add-transaction-error';
export const ACCOUNT_TAGS = '@account/account-all-tags';
export const ACCOUNT_CATEGORIES = '@account/account-all-categories';
export const EDIT_TRANSCTION_FOR_LOCAL = '@account/edit-transaction-for-local';
export const ACCOUNT_FETCH_USER = '@account/account-modified-user';
export const EMPTY_DATA_TXNS_COUNT =
  '@accountAnalysis/get-emty-data-txns-count';
export const NEW_ADDED_TXNS = '@account/new-added-transactions';
export const EDIT_TRANSACTION_RESET = '@account/edit-transction-reset';
export const GET_TRANSACTIONS_WITH_INDEXING =
  '@account/edit-transaction-with-indexing';
export const TXNS_REF_FLAG = '@account/txns-ref-flag';
export const ACCOUNT_ID_FOR_RE_ANALYSIS = '@account/account_id_renalaysis';
export const REMOVE_ADD_EDIT_DEL = '@account/remove-reset';

const operator = {
  greater: '>',
  greaterEqual: '>=',
  equal: '=',
  notEqual: '!=',
  less: '<',
  lessEqual: '<='
};

export function getAllTxns(
  accountID,
  pageNo,
  pageSize,
  order,
  orderBy,
  selectedFilters,
  indexRequired,
  isRowRefFlag
) {
  const createData = filters => {
    const body = filters.reduce((body, filter) => {
      if (filter[0] === 'txnType') {
        if (filter[2].includes('Debit') && filter[2].includes('Credit')) {
        } else {
          const value =
            filter[2] === 'Debit' ? 'DB' : filter[2] === 'Credit' ? 'CR' : '';
          body.transactionType = value;
        }
      }
      if (filter[0] === 'userAddedTransaction') {
        body.userAddedTransaction = true;
        body.txnsModifiedBy = filter[2].split(',');
      }
      if (filter[0] === 'txnMode') {
        const value = filter[2] === 'Cash' ? 'CASH' : filter[2];
        body.tags = value.replaceAll(', ', ':;');
      }

      if (filter[0] === 'tags' || filter[0] === 'category') {
        body[filter[0]] = filter[2].replaceAll(', ', ':;');
      }

      if (
        filter[0] === 'particulars' ||
        filter[0] === 'counterparty' ||
        filter[0] === 'chequeNumber'
      ) {
        body[filter[0]]
          ? (body[filter[0]] = body[filter[0]] + ':;' + filter[2])
          : (body[filter[0]] = filter[2]);
      }

      if (filter[0] === 'balance' || filter[0] === 'amount') {
        body[filter[0]]
          ? body[filter[0]].push({
              [filter[0]]: filter[2],
              operation: operator[filter[1]]
            })
          : (body[filter[0]] = [
              {
                [filter[0]]: filter[2],
                operation: operator[filter[1]]
              }
            ]);
      }

      if (filter[0] === 'txnDate') {
        if (
          moment(filter[2][0], 'DD/MM/YYYY').isValid() &&
          moment(filter[2][1], 'DD/MM/YYYY').isValid()
        ) {
          body.date = [
            {
              date: moment(filter[2][0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
              operation: operator.greaterEqual
            },
            {
              date: moment(filter[2][1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
              operation: operator.lessEqual
            }
          ];
        } else if (moment(filter[2][0], 'DD/MM/YYYY').isValid()) {
          body.date = [
            {
              date: moment(filter[2][0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
              operation: operator.greaterEqual
            }
          ];
        } else if (moment(filter[2][1], 'DD/MM/YYYY').isValid()) {
          body.date = [
            {
              date: moment(filter[2][1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
              operation: operator.lessEqual
            }
          ];
        }
      }
      return body;
    }, {});
    return body;
  };

  const url = `/api/correction/transactions/fetchTransactions?cAccountId=${accountID}&indexRequired=${
    indexRequired ? indexRequired : null
  }&page=${pageNo}&size=${pageSize}&order=${order}&sortBy=${
    orderBy === 'debit' || orderBy === 'credit' ? 'amount' : orderBy
  }`;
  const request = axiosHelper({
    url: url,
    method: 'post',
    data: createData(selectedFilters)
  });
  return dispatch => {
    dispatch({ type: LOADER });
    request
      .then(response => {
        if (indexRequired) {
          // GET_TRANSACTIONS_WITH_INDEXING;
          dispatch({
            type: GET_TRANSACTIONS_WITH_INDEXING,
            payload: response.data
          });
        } else {
          dispatch({
            type: GET_TRANSACTIONS,
            payload: response.data
          });
        }
        if (isRowRefFlag) {
          dispatch({
            type: TXNS_REF_FLAG,
            payload: true
          });
        }
      })
      .catch(error => {
        dispatch({
          type: GET_TRANSACTIONS_ERROR,
          payload: error.response && error.response.data
        });
      });
  };
}

export function emptyTransactions() {
  return dispatch => {
    dispatch({
      type: GET_TRANSACTIONS_EMPTY
    });
  };
}

export function getTags(accountID) {
  let url = `/api/correction/transactions/tags?cAccountId=${accountID}`;
  const request = axiosHelper({ url: url, method: 'get' });
  return dispatch => {
    request
      .then(response =>
        dispatch({
          type: GET_TAGS,
          payload: response.data
        })
      )
      .catch(error => {
        dispatch({
          type: GET_TAGS_ERROR,
          payload: error.response && error.response.data
        });
      });
  };
}

export function emptyTags() {
  return dispatch => {
    dispatch({
      type: GET_TAGS_EMPTY
    });
  };
}

export function getCatOptions(accountID) {
  let url = `/api/correction/transactions/uniqueCategories?cAccountId=${accountID}`;
  const request = axiosHelper({ url: url, method: 'get' });
  return dispatch => {
    request
      .then(response =>
        dispatch({
          type: GET_CATOPTIONS,
          payload: response.data
        })
      )
      .catch(error => {
        dispatch({
          type: GET_CATOPTIONS_ERROR,
          payload: error.response && error.response.data
        });
      });
  };
}

export function emptyCatOptions() {
  return dispatch => {
    dispatch({
      type: GET_CATOPTIONS_EMPTY
    });
  };
}

export function getEditTransaction(data, accountID, fromLocalComponent) {
  let url = `/api/correction/transactions/editTransaction?cAccountId=${accountID}`;
  const request = axiosHelper({ url: url, method: 'post', data: data });
  return dispatch => {
    dispatch({ type: LOADER });
    request
      .then(response => {
        if (fromLocalComponent) {
          // EDIT_TRANSCTION_FOR_LOCAL;
          dispatch({
            type: EDIT_TRANSCTION_FOR_LOCAL,
            payload: response.data && response.data.message
          });
        } else {
          dispatch({
            type: EDIT_TRANSACTION,
            payload: true
          });
        }
      })
      .catch(error => {
        dispatch({
          type: EDIT_TRANSACTION_ERROR,
          payload: error.response && error.response.data
        });
      });
  };
}

export function getAddTransaction(data, accountID) {
  let url = `/api/correction/transactions/addTransaction?cAccountId=${accountID}`;
  const request = axiosHelper({ url: url, method: 'put', data: data });
  return dispatch => {
    dispatch({ type: LOADER });
    request
      .then(response => {
        dispatch({
          type: NEW_ADDED_TXNS,
          payload: true
        });
      })
      .catch(error => {
        dispatch({
          type: EDIT_TRANSACTION_ERROR,
          payload: error.response && error.response.data
        });
      });
  };
}

export function getDeleteTransaction(txnsList, accountID) {
  let url = `/api/correction/transactions/deleteTransactions?cAccountId=${accountID}&txnIds=${txnsList}`;
  const request = axiosHelper({ url: url, method: 'delete' });
  return dispatch => {
    dispatch({ type: LOADER });
    request
      .then(response => {
        dispatch({
          type: DELETE_TRANSACTION,
          payload: true
        });
      })
      .catch(error => {
        dispatch({
          type: DELETE_TRANSACTION_ERROR,
          payload: error.response && error.response.data
        });
      });
  };
}

export function getEditTransactionFlag(data) {
  return dispatch => {
    dispatch({
      type: EDIT_TRANSACTION_RESET,
      payload: data
    });
  };
}
export function resetEditTxnsLocal() {
  return dispatch => {
    dispatch({
      type: EDIT_TRANSCTION_FOR_LOCAL,
      payload: null
    });
  };
}

export function getAccountTags(accountID) {
  let url = `/api/correction/transactions/fetchEntities?type=tags`;
  const request = axiosHelper({ url: url, method: 'get' });
  return dispatch => {
    request
      .then(response =>
        dispatch({
          type: ACCOUNT_TAGS,
          payload: response.data
        })
      )
      .catch(error => {});
  };
}

export function getAccountCategories(accountID) {
  let url = `/api/correction/transactions/fetchEntities?type=categories`;
  const request = axiosHelper({ url: url, method: 'get' });
  return dispatch => {
    request
      .then(response =>
        dispatch({
          type: ACCOUNT_CATEGORIES,
          payload: response.data
        })
      )
      .catch(error => {});
  };
}

export function emptyAnalysisTxnsCountData() {
  return dispatch => {
    dispatch({
      type: EMPTY_DATA_TXNS_COUNT,
      payload: null
    });
  };
}

export function emptyAddEditDel() {
  return dispatch => {
    dispatch({
      type: REMOVE_ADD_EDIT_DEL,
      payload: null
    });
  };
}
